.book{
    margin-top: 2%;
    display: flex;
    justify-content: space-evenly;
    height: 250px;

}

.book div{
    justify-content: center;
}

.choice span{
    font-size: 25px;
}

.bookbtn{
    text-align: center;
    font-size: 36px;
    text-decoration: none;
    height: fit-content;
    width: 100px;
    color: black;
}

.bookbtn:hover{
    font-weight: 700;
}

.title{
    width: 150px;
    font-size: 36px;
}

.choice{
    display: flex;
    align-items: center;
    width: 454px;
    height: 48px;
    margin:0px
}

table tr td{
    padding: 0%;
    border: solid 1px black;
    width: 450px;
    height: 46px;
    font-size: medium;
}

table tr td:hover{
    background-color: hsl(0, 0%, 0%, 0.24);
}
