.main2{
    background-color: aliceblue;
    display: flex;
    flex-direction: column;
    width: 60%;
    margin-top: 50px;
    margin-left: 20%;
    text-align: center;
    border-radius: 30px;
}

.main2 div{
    width: 100%;
    margin: 20px;
    font-size: larger;
    justify-content: space-evenly;
}

.main2 div button{
    width: 62px;
}

.main2 div span{
    margin-left: 10px;
    margin-right: 10px;
}

.corn button{
    margin-left: 5px;
    margin-right: 5px;
}