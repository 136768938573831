.App {
  text-align: center;
}

.Header {
  display: inline-flex;
  position: relative;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  border-bottom: solid 1px;
}

.logo{
  font-size: 36px;
  text-decoration: none;
  color: black;
  font-weight: 700;
}

.Header nav ul{
  display: flex;
  width: 100%;
}

.Header nav ul li{
  list-style: none;
  padding: 50px
}

.Header nav ul li a{
  text-decoration: none;
  color: black;
  font-weight: 500;
}

.Header nav ul li a:hover {
 font-weight: 700;
}


@media(width<780px){
  .Header nav ul li{
      padding:5px;
  }

  .Header h1{
    font-size: 15px;
  }
}
