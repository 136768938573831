.login-items {
    display: flex;
    flex-direction: column;
    width:40%;
    margin-left: 30%;
    margin-top: 50px;
    height:500px;
    align-items: center;
    background-color: aliceblue;
    border-radius: 15px;
    border: solid 2px hsl(242, 46%, 50%);
}

.labels {
    display: block;
    width: 200px
}

.labels-top {
    margin-top: 70px;
    display: block;
    width: 200px
}

.login-items label{
    font-size: 24px;
}

.login-items input{
    height: 24px;
    font-size: 20px;
    width:192.3px;
}

.login-items button {
    margin-top: 20px;
}

.login-items h1{
    margin-top: 50px;
}