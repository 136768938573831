.about img{
    align-self: center;
    margin-left: 18%;
}

.about{
    margin-left: 5%;
    margin-right: 5%;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 22px;
}