.Footer {
  margin-top: 50px;
  height: 150px;
  padding-left: 2%;
  display: flex;
  position: relative;
  justify-content: flex-start;
  align-items: center;
  width: 98%;
  background-color: black;
}

.logo2{
  font-size: 36px;
  text-decoration: none;
  font-weight: 700;
  color: white;
  text-align: center;
}

.Footer nav ul{
  display: block;
  width: 100%;
}

.Footer nav ul li{
  list-style: none;
}

.Footer nav ul li a{
  text-decoration: none;
  color: white;
  font-weight: 500;
}

.Footer nav ul li a:hover {
 font-weight: 700;
}


@media(width<780px){
  .Footer nav ul li{
      padding:5px;
  }

  .Footer h1{
    font-size: 15px;
  }
}
