.message {
    justify-content: center;
}

.message section{
    display: flex;
}

.message section div{
    display: flex;
    flex-direction: column;
    font-size: larger;
}

.name{
    margin-left: 25%;
}

.email{
    margin-right:25%
}

.nameemail{
    justify-content: space-between;
}

.mesage{
    width:100%;
    height: 300px;
    margin-left: 25%;
    margin-right:25%;
}
.messageInput {
    font-family: Arial, Helvetica, sans-serif;
    display:block;
    height: 100%;
    resize: none;
}

.message button{
    background-color: black;
    color:white;
    width:10%;
    margin-left: 45%;
}