.main{
    width: 100%;
    display: inline;
}

.main ul{
    display: flex;
    overflow-x: scroll;
}

.main ul li{
    list-style: none;
}

.main ul li img{
    width: 100vh;
}


@media(width<780px){
    .main ul{
        display:inline;
    }

    .main ul li img{
        width:100%;
    }
}